storeApp.controller('listController', function($scope, $http) {

	$scope.vouchers = [];

	$http.get('/api/vouchers').then(
		function successCallback(response) {
			$scope.vouchers = response.data.vouchers
		},
		function errorCallback(response) {
				
		}
	);

});