var storeApp = angular.module('storeApp', []);

storeApp.controller('storeController', function($scope, $http) {

	$scope.products = [];

	$http.get('/api/products').then(
		function successCallback(response) {
			$scope.products = response.data.products
		},
		function errorCallback(response) {
				
		}
	);

});

storeApp.filter('mysqlDateToCH', function($filter) {
  	return function(input) {
		if(typeof input === 'string') {
      		var t = input.split(/[- :]/);
			input = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
			input = $filter('date')(input, 'dd/MM/yyyy hh:mm:ss');
   		}
   		return input;
  	};
});